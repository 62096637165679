import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/callBombRequests', params)
  },
  list(page) {
    return Api().get('/api/callBombRequests?page=' + page)
  },
  delete() {
    return Api().delete('/api/callBombRequests')
  },
}
