<template>
  <div class="dump-page animated fadeIn container">
    <h1>콜폭탄</h1>
    <h2 class="mt-2 color-skyblue">{{ availableSendCount }}건 발송가능</h2>

    <ul class="request-form">
      <li>
        <div>수신번호</div>
        <div><b-form-input type="number" value="0" v-model="recipient" placeholder="전화번호" /></div>
      </li>
      <li>
        <div>동시콜수</div>
        <div>
          동시에<b-form-input
            type="number"
            value="0"
            v-model="interval1"
            style="margin-left: 5px; width: 80px"
            min="0"
          />건의 콜을 보냅니다
        </div>
      </li>
      <li>
        <div>자동반복</div>
        <div>
          <b-form-input type="number" value="0" v-model="interval2" min="0" style="width: 80px" />초 간격으로 총
          <b-form-input type="number" value="0" v-model="totalCount" min="0" style="margin-left: 5px; width: 80px" />회
          콜을 보냅니다
        </div>
      </li>
    </ul>

    <b-btn id="create-btn" ref="createBtn" variant="primary" class="fl" @click.prevent="create">발송</b-btn>

    <table class="border-table mt-4 mb-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="200px">발송시각</td>
          <td>내용</td>
          <td width="300px">상태</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>{{ item.createdAt | dateFormat }}</td>
          <td>
            <span class="color-skyblue">{{ item.recipient | phoneFormat }}</span
            >의 수신번호로 콜을 발신합니다(동시콜수 <span class="color-skyblue">{{ item.interval1 }}</span
            >, 자동반복 <span class="color-skyblue">{{ item.totalCount }}</span
            >)
          </td>
          <td>
            발신 <span class="color-skyblue">{{ item.totalCount * item.interval1 }}</span> / 成功
            <span class="color-skyblue">{{ (item.totalCount - item.remainCount) * item.interval1 }}</span> / 等待
            <span class="color-skyblue">{{ item.remainCount * item.interval1 }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-show="contentTotalCount > 0">
      <b-pagination
        align="center"
        :total-rows="contentTotalCount"
        v-model="currentPage"
        :per-page="20"
        :limit="10"
        class="mt-4"
        @change="changePage"
      ></b-pagination>
      <b-btn class="btn-danger" @click="deleteAllRequests()" style="float: right; margin-top: -40px">모두 삭제</b-btn>
    </div>
  </div>
</template>
<style scoped lang="scss">
.request-form {
  display: block;
  margin: 50px auto 20px;
  width: 800px;

  > li {
    border-bottom: 1px solid #ededed;
    display: flex;
    flex-flow: row;
    align-items: center;

    &:last-of-type {
      border-bottom: none;
    }

    > div {
      padding: 10px;
      display: flex;
      flex-flow: row;
      flex-grow: 1;
      align-items: center;

      .form-control {
        margin-right: 5px;
      }

      &:first-of-type {
        max-width: 200px;
      }
    }
  }
}

#create-btn {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
}
</style>
<script>
import CallBombService from '@/services/CallBombService'
import { mapGetters } from 'vuex'

export default {
  name: 'CallBomb',
  components: {},
  data() {
    return {
      items: [],
      isLoading: false,
      availableSendCount: 0,
      recipient: '',
      interval1: 0,
      interval2: 0,
      totalCount: 0,
      currentPage: 1,
      contentTotalCount: 0,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getUserInfo()
    this.getList()
  },
  methods: {
    async getList() {
      const response = await CallBombService.list(this.currentPage || 0)
      if (response.status == 200) {
        this.items = response.data.list
        this.contentTotalCount = response.data.totalCount
      }
    },
    async create() {
      try {
        this.isLoading = true

        var params = {
          recipient: this.recipient,
          totalCount: this.totalCount,
          interval1: this.interval1,
          interval2: this.interval2,
        }

        const response = await CallBombService.create(params)
        if (response.status !== 200) {
          return
        }
        this.getUserInfo()
        this.getList()
      } catch (e) {
        // Do nothing
      } finally {
        this.isLoading = false
      }
    },
    changePage(page) {
      var query = {
        page: page,
      }

      this.$router.push({
        path: '/callBomb',
        query,
      })
    },
    async deleteAllRequests() {
      if (!confirm('삭제하시겠습니까?')) {
        return
      }
      const response = await CallBombService.delete()
      if (response.status == 200) {
        alert('成功')
        this.changePage(1)
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    isLoading() {
      this.$refs.createBtn.disabled = this.isLoading
    },
    user() {
      this.availableSendCount = parseInt(this.user.money / 11)
    },
    availableSendCount() {},
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getList()
    },
  },
}
</script>
